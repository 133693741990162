<template>
  <EuiContainer v-if="paraph">
    <EuiGrid gutters>
      <EuiGridRow>
        <EuiGridColumn>
          <div class="m-0 flex items-center">
<!--            <EuiButton iconOnly size="large" variant="text" @click="goBack">
              <EuiIcon name="chevron_left" color="primary"/>
            </EuiButton>-->
            <EuiHeading :level="1" bold>{{ $t('signworkflows.details.title') }}</EuiHeading>
          </div>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow>
        <EuiGridColumn>
          <EuiBlock :title="paraph.name">
            <div class="eui-u-flex" v-if="currentParaphStep !== undefined">
              <div class="eui-u-text-blue-grey eui-u-font-bold eui-u-mr-2">{{ $t('signworkflows.create.deadline') }}:</div>
              <div>{{ $d(new Date(paraph.steps[currentParaphStep].limitSignatureDate), 'short') }}</div>
            </div>
            <div class="eui-u-flex" v-if="currentParaphStep !== undefined && paraph.steps[currentParaphStep].comment != null &&
                                                                       paraph.steps[currentParaphStep].comment.length">
              <div class="eui-u-text-blue-grey eui-u-font-bold eui-u-mr-2">{{ $t('signworkflows.create.comment.label') }}:</div>
              <div>{{ paraph.steps[currentParaphStep].comment }}</div>
            </div>
            <div class="es-paraph__step-container">
              <div :class="['es-paraph__step-bubble',
                        'es-paraph__step-bubble--no-hover',
                       { 'complete':index <= currentParaphStep }]"
                   v-for="(paraphStep, index) in paraph.steps" :key="paraphStep.signBookStepId">
                <template v-if="index <= currentParaphStep">
                  <!-- bubble signatory -->
                  <EuiIcon name="person"/>
                  <span class="es-paraph__step-bubble__label" v-if="paraphStep.signerName">
                {{ paraphStep.signerName.slice(0, 15) }}<span v-if="paraphStep.signerName.length > 15">...</span>
              </span>
                  <!-- bubble nb of document -->
                  <EuiIcon size="mini" name="file"/>{{ paraphStep.docsToSign.length }}
                  <span class="es-paraph__step-bubble__title">{{ $t('signworkflows.create.step') }} {{ index + 1}}
                <br><template v-if="index <= currentParaphStep">
                  ({{ paraph.steps[index].executionDate.split(' ')[0].split('-').reverse().join('/') }})
                </template>
              </span>
                </template>
                <div class="es-paraph__step-bubble__line" v-if="index < (paraph.steps.length - 1)"></div>
              </div>
            </div>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
      <EuiGridRow v-if="currentParaphStep !== undefined">
        <EuiGridColumn>
          <EuiBlock :title="$t('signworkflows.details.document.title')">
            <EuiList>
              <EuiListItem button v-for="document in paraph.documents" :key="document.signBookDocumentId" @click="showDoc(document)">
                <template v-slot:meta>
                  <EuiButton color="primary" @click.stop="downloadDoc([document.signBookDocumentId, document.name])">
                    {{ $t('button.download') }}
                  </EuiButton>
                </template>
                {{ document.name }}
              </EuiListItem>
            </EuiList>
          </EuiBlock>
        </EuiGridColumn>
      </EuiGridRow>
    </EuiGrid>
      <!-- preview document dialog -->
      <documentPreviewDialog
        :document="documentDialog"
        @closeDialog="documentDialog = undefined"
        v-if="documentDialog"/>
  </EuiContainer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'thirdPartySignworkflow',
  components: {
    documentPreviewDialog: () => import(/* webpackPrefetch: true */ '../../pages/paraphers/dialogs/DocumentPreviewDialog'),
  },
  data() {
    return {
      currentParaphStep: undefined,
      currentStatus: undefined,
      status: {
        expired: this.$i18n.t('signworkflows.details.stepState.expired'),
        completed: this.$i18n.t('signworkflows.details.stepState.complete'),
        refused: this.$i18n.t('signworkflows.details.stepState.refuse'),
        invalid: this.$i18n.t('signworkflows.details.stepState.incomplete'),
      },
      signDialog: [],
      refuseDialog: [],
      // document viewer
      documentDialog: undefined,
      documentPositions: undefined,
      // errors data
      invalidSteps: [],
      invalidDeadlines: [],
      // paraph object
      paraph: undefined,
    };
  },
  computed: {
    ...mapGetters({
      user: 'ModuleEdocSign/auth/user',
    }),
  },
  methods: {
    ...mapActions({
      getSignworflow: 'ModuleEdocSign/parapheurs/getByThirdPartyId',
      getUserInfos: 'ModuleEdocSign/auth/getUserInfos',
      getDocContentWithUUID: 'ModuleEdocSign/parapheurs/getDocContentWithUUID',
      downloadDoc: 'ModuleEdocSign/parapheurs/downloadDoc',
    }),
    showDoc(doc) {
      this.getDocContentWithUUID({ id: doc.signBookDocumentId })
        .then((response) => {
          this.documentPositions = [];
          for (let i = 0; i < this.paraph.steps.length; i++) {
            const index1 = JSON.parse(this.paraph.steps[i].signatureParams).positions.findIndex((x) => x.signBookDocumentId === doc.signBookDocumentId);
            if (index1 >= 0) {
              this.documentPositions.push(JSON.parse(this.paraph.steps[i].signatureParams).positions[index1]);
            }
          }
          doc.fileContent = response.fileContent;
          this.documentDialog = { id: doc.signBookDocumentId, content: this.base64ToFile(doc), fileContent: doc.fileContent };
        })
        .catch((error) => { console.log('Error: ', error); });
    },
    base64ToFile(doc) {
      const binary = window.atob(doc.fileContent);
      const converter = new ArrayBuffer(binary.length);
      const array = new Uint8Array(converter);
      for (let i = 0; i < binary.length; i += 1) {
        array[i] = binary.charCodeAt(i);
      }
      return new File([converter], doc.name, { type: 'application/pdf' });
    },
    closeDialog(needReload) {
      this.signDialog = [];
      this.refuseDialog = [];
      if (needReload) {
        this.goBack();
      }
    },
    goBack() {
      this.$router.push({ path: '/guestSignworkflows/tp-access' });
    },
    setCurrentParaphStep(index) {
      if (index !== this.currentParaphStep) {
        this.currentParaphStep = index;
      } else {
        this.currentParaphStep = undefined;
        this.currentStatus = undefined;
      }
    },
  },
  mounted() {
    // rechercher le signbook en fonction de l'uuid stocker dans le storage.
    console.log('should search third party signbook');
    this.getSignworflow().then((signworkflow) => {
      this.paraph = signworkflow;
      this.currentParaphStep = this.paraph.steps.length - 1;
    });

    /* if (this.signworkflow === undefined) {
      this.$router.push({ name: 'signworkflows' });
    } else {
      this.paraph = JSON.parse(JSON.stringify(this.signworkflow));
      this.paraph.alertConfiguration.intervall = this.paraph.alertConfiguration.intervall / 86400; // from secs to days

      if (this.paraph.finalizedDate !== null) {
        for (var i = 0; i < this.paraph.steps.length; i++) {
          if (this.paraph.steps[i].userId === this.user.userId) {
            this.currentParaphStep = i;
          }
        }
        console.log(this.user);
        console.log(this.paraph);
      } else {
        this.currentParaphStep = this.paraph.nbStepsRealized;
      }
    }
    if (this.user === undefined) {
      this.getUserInfos();
    } */
  },
};
</script>
